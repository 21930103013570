/*
// .block-space
*/
@import '../variables';


@if($header-layout == classic) {
    .block-space--layout--spaceship-ledge-height {
        display: none;
    }
}
