/* ----------------------------------------
// Table of Contents:
// - Components
//   - .breadcrumb
// - Header
//   - .account-menu
//   - .departments
//   - .dropcart
//   - .header
//   - .indicator
//   - .logo
//   - .main-menu
//   - .megamenu
//   - .megamenu-links
//   - .menu
//   - .phone
//   - .search
//   - .suggestions
//   - .topbar
//   - .vehicle-picker
// - Pages
//   - .about
// - Blocks
//   - .block-header
//   - .block-space
// - Blog
//   - .post-header
// ---------------------------------------- */
/* ----------------------------------------
// Components
// ---------------------------------------- */
/*
// .breadcrumb
*/
/* ----------------------------------------
// Header
// ---------------------------------------- */
/*
// .account-menu
*/
.account-menu {
  width: 280px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1.5px;
  color: #262626;
}

.account-menu__form {
  padding: 0 30px 32px;
}

.account-menu__form-title {
  text-align: center;
  padding: 32px 0 26px;
  font-weight: 500;
}

.account-menu__form-button {
  margin-top: 32px;
  text-align: center;
}

.account-menu__form-button .btn {
  padding-left: 28px;
  padding-right: 28px;
}

.account-menu__form-link {
  font-size: 14px;
  text-align: center;
}

.account-menu__form-link a {
  color: #6c757d;
  transition: color .1s;
}

.account-menu__form-link a:hover {
  color: inherit;
}

.account-menu__form-forgot {
  position: relative;
}

.account-menu__form-forgot-link {
  position: absolute;
  top: 5px;
  bottom: 5px;
  border-radius: 2px;
  font-size: 12px;
  background: fff;
  color: #6c757d;
  display: flex;
  align-items: center;
  padding: 0 7px;
  transition: background .1s, color .1s;
  left: 5px;
}

.account-menu__form-forgot-link:hover {
  background: #333;
  color: #fff;
}

.account-menu__form-forgot-link:active {
  background: #4d4d4d;
  color: #fff;
}

.account-menu__divider {
  height: 1px;
  background: #ebebeb;
}

.account-menu__user {
  display: flex;
  align-items: center;
  padding: 14px 20px;
  color: inherit;
}

.account-menu__user:hover {
  color: inherit;
  background: #f2f2f2;
}

.account-menu__user-avatar {
  width: 44px;
  flex-shrink: 0;
  margin-left: 14px;
}

.account-menu__user-avatar img {
  border-radius: 100%;
  max-width: 100%;
}

.account-menu__user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.account-menu__user-name {
  font-size: 15px;
  line-height: 20px;
}

.account-menu__user-email {
  font-size: 14px;
  line-height: 18px;
  color: #6c757d;
  margin-top: 1px;
}

.account-menu__links {
  list-style: none;
  padding: 12px 0;
  margin: 0;
}

.account-menu__links a {
  display: block;
  color: inherit;
  font-size: 15px;
  padding: 5px 20px;
  font-weight: 500;
}

.account-menu__links a:hover {
  background: #f2f2f2;
}

/*
// .departments
*/
.departments {
  position: relative;
}

.departments__button {
  position: relative;
  fill: currentColor;
  border: none;
  border-radius: 2px;
  font-family: inherit;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: background-color .2s, color .2s;
  padding-right: 35px;
  padding-left: 11px;
  text-align: right;
}

.departments__button:focus {
  outline: none;
}

.departments__button-icon {
  top: 10px;
  position: absolute;
  fill: currentColor;
  display: inline-block;
  transition: color .2s;
  right: 10px;
  transform: scaleX(-1);
}

.departments__button-icon svg {
  display: block;
}

.departments__button-arrow {
  display: none;
  fill: currentColor;
  position: absolute;
  top: calc(50% - 4px);
  transition: transform .2s, color .2s;
  left: 14px;
}

.departments__button-arrow svg {
  display: block;
}

.departments__menu {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  transform: translateY(-10px);
  transition: opacity .2s, transform .2s, visibility 0s .2s;
}

.departments__body {
  border-radius: 1.5px;
  pointer-events: auto;
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  color: #262626;
  float: right;
}

.departments__list {
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
}

.departments__item-link {
  color: inherit;
  display: block;
  position: relative;
}

.departments__item-arrow {
  fill: #bfbfbf;
  position: absolute;
  top: calc(50% - 6px);
}

.departments__item-arrow svg {
  display: block;
}

.departments__item-menu {
  display: none;
}

.departments__item--hover .departments__item-link {
  background: #f2f2f2;
  color: inherit;
}

.departments__megamenu {
  min-height: 100%;
  display: none;
  box-shadow: -1px 0 #ebebeb inset;
}

.departments__megamenu--open {
  display: block;
}

.departments__arrow {
  position: absolute;
  width: 31px;
  height: 21px;
  pointer-events: none;
  bottom: 100%;
  overflow: hidden;
}

.departments__arrow:before {
  position: absolute;
  display: block;
  content: '';
  width: 7px;
  height: 7px;
  top: 100%;
  margin-top: -3.5px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  right: 50%;
  margin-right: -3.5px;
  transform: rotate(45deg) translateX(0.5px) translateY(-0.5px);
}

.departments--open .departments__menu {
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.departments--open .departments__button-arrow {
  transform: rotate(180deg);
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .departments__button {
    height: 32px;
    display: block;
    padding-left: 0;
  }
  .departments__button-title {
    display: none;
  }
}

@media (min-width: 1400px) {
  .departments__list {
    width: 230px;
  }
  .departments__list-padding {
    height: 14px;
  }
  .departments__item-link {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 20px;
    padding-left: 29px;
  }
  .departments__item-arrow {
    left: 14px;
    transform: scaleX(-1);
  }
  .departments__megamenu {
    padding: 27px 30px 30px;
  }
  .departments__megamenu--size--xl {
    width: 1120px;
  }
  .departments__megamenu--size--lg {
    width: 902px;
  }
  .departments__megamenu--size--md {
    width: 684px;
  }
  .departments__megamenu--size--nl {
    width: 466px;
  }
  .departments__megamenu--size--sm {
    width: 248px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .departments__list {
    width: 210px;
  }
  .departments__list-padding {
    height: 10px;
  }
  .departments__item-link {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 17px;
    padding-left: 26px;
  }
  .departments__item-arrow {
    left: 11px;
    transform: scaleX(-1);
  }
  .departments__megamenu {
    padding: 17px 20px 20px;
  }
  .departments__megamenu .row {
    margin: 0 -10px;
  }
  .departments__megamenu .col {
    padding: 0 10px;
  }
  .departments__megamenu .col-1 {
    padding: 0 10px;
  }
  .departments__megamenu .col-2 {
    padding: 0 10px;
  }
  .departments__megamenu .col-3 {
    padding: 0 10px;
  }
  .departments__megamenu .col-4 {
    padding: 0 10px;
  }
  .departments__megamenu .col-5 {
    padding: 0 10px;
  }
  .departments__megamenu .col-6 {
    padding: 0 10px;
  }
  .departments__megamenu .col-7 {
    padding: 0 10px;
  }
  .departments__megamenu .col-8 {
    padding: 0 10px;
  }
  .departments__megamenu .col-9 {
    padding: 0 10px;
  }
  .departments__megamenu .col-10 {
    padding: 0 10px;
  }
  .departments__megamenu .col-11 {
    padding: 0 10px;
  }
  .departments__megamenu .col-12 {
    padding: 0 10px;
  }
  .departments__megamenu .col-1of1 {
    padding: 0 10px;
  }
  .departments__megamenu .col-1of2 {
    padding: 0 10px;
  }
  .departments__megamenu .col-1of3 {
    padding: 0 10px;
  }
  .departments__megamenu .col-1of4 {
    padding: 0 10px;
  }
  .departments__megamenu .col-1of5 {
    padding: 0 10px;
  }
  .departments__megamenu--size--xl {
    width: 910px;
  }
  .departments__megamenu--size--lg {
    width: 732px;
  }
  .departments__megamenu--size--md {
    width: 554px;
  }
  .departments__megamenu--size--nl {
    width: 376px;
  }
  .departments__megamenu--size--sm {
    width: 198px;
  }
}

.departments__button {
  background: #fff;
  color: #262626;
  font-weight: 500;
}

.departments__button .departments__button-icon {
  color: #404040;
}

.departments__button .departments__button-arrow {
  color: #bfbfbf;
}

.departments__button:hover,
.departments--open .departments__button {
  background: #204260;
  color: #fff;
}

.departments__button:hover .departments__button-icon,
.departments--open .departments__button .departments__button-icon {
  color: #fff;
}

.departments__button:hover .departments__button-arrow,
.departments--open .departments__button .departments__button-arrow {
  color: rgba(0, 0, 0, 0.25);
}

/*
// .dropcart
*/
.dropcart {
  width: 320px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1.5px;
  padding: 24px;
  color: #262626;
}

.dropcart__list {
  list-style: none;
  padding: 0;
  margin: -8px 0 0;
}

.dropcart__divider {
  height: 1px;
  background: #ebebeb;
  margin: 8px 0;
}

.dropcart__item {
  display: flex;
}

.dropcart__item-image {
  flex-shrink: 0;
  width: 70px;
}

.dropcart__item-info {
  padding: 4px 0 5px;
  margin: 0 10px;
}

.dropcart__item-name {
  font-size: 15px;
  line-height: 18px;
}

.dropcart__item-name a {
  color: inherit;
  transition: color .2s;
}

.dropcart__item-name a:hover {
  color: #162d41;
}

.dropcart__item-features {
  list-style: none;
  padding: 0;
  margin-top: 2px;
  font-size: 13px;
  line-height: 17px;
  color: #6c757d;
}

.dropcart__item-meta {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.dropcart__item-remove {
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  fill: currentColor;
  background-color: #fff;
  color: #ccc;
  margin-left: -13px;
}

.dropcart__item-remove:hover {
  background-color: #f2f2f2;
  color: #dfdfdf;
}

.dropcart__item-remove:active {
  background-color: #ebebeb;
  color: #dfdfdf;
}

.dropcart__item-remove:focus {
  outline: none;
}

.dropcart__item-quantity,
.dropcart__item-price {
  position: relative;
  height: 16px;
  z-index: 0;
  font-size: 11px;
  padding: 1px 10.82352px 0;
}

.dropcart__item-quantity:before,
.dropcart__item-price:before {
  position: absolute;
  display: block;
  content: '';
  width: calc(100% - 5.82352px);
  height: 100%;
  top: 0;
  z-index: -1;
  border-radius: 2.5px 3px;
  right: 0;
  transform: skewX(20deg);
  transform-origin: right bottom;
}

.dropcart__item-quantity {
  color: #fff;
  font-weight: 500;
  padding-right: 6px;
  margin-left: -2.82352px;
}

@media (max-resolution: 1dppx) {
  .dropcart__item-quantity {
    font-weight: 400;
  }
}

.dropcart__item-quantity:before, .dropcart__item-quantity:after {
  background: #204260;
}

.dropcart__item-quantity:after {
  position: absolute;
  display: block;
  content: '';
  width: 8.32352px;
  height: 100%;
  top: 0;
  z-index: -1;
  right: 0;
  border-top-right-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
}

.dropcart__item-price {
  color: #262626;
  font-weight: 500;
}

.dropcart__item-price:before {
  background: #ebebeb;
}

.dropcart__totals {
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 24px;
}

.dropcart__totals table {
  width: 100%;
}

.dropcart__totals th, .dropcart__totals td {
  padding: 0;
}

.dropcart__totals th {
  font-weight: 500;
}

.dropcart__totals td {
  text-align: left;
}

.dropcart__totals tr + tr > * {
  padding-top: 4px;
}

.dropcart__actions {
  display: flex;
}

.dropcart__actions > * {
  flex-grow: 1;
}

.dropcart__actions > * + * {
  margin-right: 8px;
}

/*
// .header
*/
.header {
  display: grid;
  grid-template-columns: calc(100% / 2 - 1350px / 2) 1fr auto 1fr calc(100% / 2 - 1350px / 2);
  grid-template-rows: 30px auto;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .header {
    grid-template-columns: calc(100% / 2 - 1110px / 2) 1fr auto 1fr calc(100% / 2 - 1110px / 2);
  }
}

.header__megamenu-area {
  grid-column: 2 / 5;
  grid-row: 1;
}

.header__topbar-start-bg {
  grid-column: 1 / 3;
  grid-row: 1;
}

.header__topbar-end-bg {
  grid-column: 4 / 6;
  grid-row: 1;
}

.header__topbar-start {
  grid-column: 2;
  grid-row: 1;
}

.header__topbar-end {
  grid-column: 4;
  grid-row: 1;
}

.header__navbar {
  grid-column: 2;
  grid-row: 2;
}

.header__logo {
  grid-column: 3;
  grid-row: 1 / 3;
}

.header__search {
  grid-column: 3 / 3;
  grid-row: 1 / 3;
}

.header__indicators {
  grid-column: 4;
  grid-row: 2;
}

.header {
  position: relative;
  z-index: 10;
}

@media (max-width: 1199.98px) {
  .header {
    display: none;
  }
}

.header__navbar {
  align-self: center;
  display: flex;
  align-items: center;
}

.header__topbar-start-bg,
.header__topbar-end-bg {
  height: 100%;
  overflow: hidden;
}

.header__topbar-start-bg:before,
.header__topbar-end-bg:before {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
}

.header__topbar-start,
.header__topbar-end {
  z-index: 1;
}

.header__topbar-start {
  padding-left: 20px;
}

.header__topbar-end {
  padding-right: 20px;
}

.header__navbar {
  padding: 10px 0;
}

.header__navbar-departments {
  z-index: 2;
}

.header__navbar-menu {
  z-index: 2;
}

.header__indicators {
  align-self: center;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.header__indicators .indicator + .indicator {
  margin-right: 4px;
}

.header__topbar-start-bg:before {
  border-bottom-left-radius: 2.5px;
  transform: skewX(30deg);
  transform-origin: top left;
}

.header__topbar-end-bg:before {
  border-bottom-right-radius: 2.5px;
  transform: skewX(-30deg);
  transform-origin: top right;
}

.header__logo {
  padding: 0 20px;
  display: flex;
  align-items: stretch;
}

.header__navbar {
  min-height: 80px;
}

.header__search {
  position: absolute;
  align-self: flex-end;
  justify-self: center;
  bottom: -24px;
  width: 480px;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .header__search {
    width: 440px;
  }
}

.header {
  color: #262626;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.header__topbar-start-bg:before {
  background: #204260;
}

.header__topbar-end-bg:before {
  background: #D62828;
}

.header__logo {
  padding-bottom: 22px;
}

/*
// .indicator
*/
.indicator {
  position: relative;
}

.indicator__button {
  padding: 3px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 54px;
  min-width: 54px;
  color: inherit;
  border-radius: 2px;
  background: transparent;
  border: none;
  padding-left: 12px;
}

.indicator__button:focus {
  outline: none;
}

.indicator__button:hover {
  color: inherit;
  text-decoration: none;
}

.indicator__icon {
  position: absolute;
  padding: 8px;
  display: block;
  width: 48px;
  height: 48px;
}

.indicator__icon svg {
  display: block;
  transform: scaleX(-1);
}

.indicator__counter {
  position: absolute;
  top: 5px;
  font-size: 10px;
  line-height: 1;
  padding: 2px 3px 1px;
  border-radius: 6.5px;
  text-align: center;
  z-index: 0;
  left: 4px;
}

.indicator__counter:before {
  display: block;
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 2.5px;
  right: 0;
  left: 0;
  transform: skewX(11deg);
}

.indicator__title,
.indicator__value {
  display: block;
  margin-right: 50px;
}

.indicator__title {
  padding-top: 3px;
  margin-bottom: -3px;
  font-size: 13px;
}

.indicator__value {
  font-size: 18px;
  font-weight: 500;
}

.indicator__content {
  top: 100%;
  position: absolute;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
  left: 0;
}

/*
// .indicator--trigger--click.indicator--open,
// .indicator--trigger--hover:hover
*/
.indicator--trigger--click.indicator--open .indicator__content,
.indicator--trigger--hover:hover .indicator__content {
  z-index: 1;
  transition-delay: 0s, 0s, 0s;
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
}

.indicator--mobile .indicator__button {
  padding: 0 0 2px;
  height: 50px;
  width: 44px;
  min-width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}

.indicator--mobile .indicator__icon {
  padding: 0;
  position: relative;
  height: auto;
  width: auto;
}

.indicator--mobile .indicator__counter {
  top: -7px;
  left: -7px;
}

.indicator__icon {
  fill: #262626;
}

.indicator__title {
  color: #999;
}

.indicator__value {
  font-weight: 500;
}

.indicator:hover .indicator__button,
.indicator--open .indicator__button {
  background: #f2f2f2;
}

.indicator__counter {
  color: #fff;
  font-weight: 500;
}

@media (max-resolution: 1dppx) {
  .indicator__counter {
    font-weight: 400;
  }
}

.indicator__counter:before {
  background-color: #204260;
}

/*
// .logo
*/
.logo__slogan {
  padding-top: 11px;
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
}

.logo__image {
  display: flex;
  align-items: center;
  height: 50px;
}

.logo {
  display: flex;
  flex-direction: column;
}

.logo__image {
  justify-content: center;
  flex-grow: 1;
}

.logo__slogan {
  color: #999;
  font-weight: 500;
}

.logo__part-primary {
  fill: #204260;
}

.logo__part-secondary {
  fill: #404040;
}

/*
// .main-menu
*/
.main-menu {
  height: 100%;
}

.main-menu__list {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.main-menu__link {
  display: block;
  padding: 5px 11px;
  border-radius: 2px;
  color: inherit;
  white-space: nowrap;
  font-size: 15px;
}

.main-menu__link:hover {
  color: inherit;
}

.main-menu__submenu {
  color: #262626;
}

.main-menu__item--has-submenu .main-menu__link {
  position: relative;
  padding-left: 23px;
}

.main-menu__item--has-submenu .main-menu__link svg {
  top: 14px;
  position: absolute;
  fill: currentColor;
  left: 10px;
}

.main-menu__item--submenu--menu {
  position: relative;
}

.main-menu__item--submenu--menu .main-menu__submenu {
  position: absolute;
  z-index: 1;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(45deg);
  transition: transform .2s, opacity .2s;
  right: 0;
}

.main-menu__item--submenu--menu:hover .main-menu__submenu {
  visibility: visible;
  opacity: 1;
  transform: rotateX(0);
}

.main-menu__item--submenu--megamenu .main-menu__submenu {
  position: absolute;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(45deg);
  transition: transform .2s, opacity .2s;
}

.main-menu__item--submenu--megamenu:hover .main-menu__submenu {
  visibility: visible;
  opacity: 1;
  transform: rotateX(0);
}

.main-menu__megamenu {
  padding: 26px 30px 30px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

@media (min-width: 1400px) {
  .main-menu__megamenu--size--xxl {
    width: calc((1350px - 30px) / 6 * 6 + 30px);
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .main-menu__megamenu--size--xxl {
    width: calc((1110px - 30px) / 6 * 6 + 30px);
  }
}

@media (min-width: 1400px) {
  .main-menu__megamenu--size--xl {
    width: calc((1350px - 30px) / 6 * 5 + 30px);
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .main-menu__megamenu--size--xl {
    width: calc((1110px - 30px) / 6 * 5 + 30px);
  }
}

@media (min-width: 1400px) {
  .main-menu__megamenu--size--lg {
    width: calc((1350px - 30px) / 6 * 4 + 30px);
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .main-menu__megamenu--size--lg {
    width: calc((1110px - 30px) / 6 * 4 + 30px);
  }
}

@media (min-width: 1400px) {
  .main-menu__megamenu--size--md {
    width: calc((1350px - 30px) / 6 * 3 + 30px);
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .main-menu__megamenu--size--md {
    width: calc((1110px - 30px) / 6 * 3 + 30px);
  }
}

@media (min-width: 1400px) {
  .main-menu__megamenu--size--nl {
    width: calc((1350px - 30px) / 6 * 2 + 30px);
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .main-menu__megamenu--size--nl {
    width: calc((1110px - 30px) / 6 * 2 + 30px);
  }
}

@media (min-width: 1400px) {
  .main-menu__megamenu--size--sm {
    width: calc((1350px - 30px) / 6 * 1 + 30px);
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .main-menu__megamenu--size--sm {
    width: calc((1110px - 30px) / 6 * 1 + 30px);
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .main-menu__link {
    padding-right: 9px;
    padding-left: 21px;
  }
  .main-menu__link svg {
    left: 9px;
  }
}

.main-menu .main-menu__link {
  font-weight: 500;
}

.main-menu .main-menu__item--has-submenu .main-menu__link svg {
  color: #bfbfbf;
}

.main-menu .main-menu__item:hover .main-menu__link {
  background-color: #f2f2f2;
}

/*
// .megamenu
*/
.megamenu {
  position: relative;
}

.megamenu__image {
  position: absolute;
  bottom: 0;
  left: 0;
  transform-origin: bottom left;
}

.megamenu__image img,
.megamenu__image picture {
  display: block;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .megamenu__image {
    transform: scale(0.81);
  }
}

/*
// .megamenu-links
*/
.megamenu-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.megamenu-links__item {
  line-height: 18px;
}

.megamenu-links__item + .megamenu-links__item {
  margin-top: 10px;
}

.megamenu-links__item--has-submenu + .megamenu-links__item {
  margin-top: 24px;
}

.megamenu-links__item-link {
  font-size: 16px;
  color: inherit;
  transition: color .15s;
  font-weight: 500;
}

.megamenu-links__item-link:hover {
  color: #162d41;
}

.megamenu-links__item-link + .megamenu-links {
  border-top: 1px solid #ebebeb;
  padding-top: 12px;
  margin-top: 5px;
}

.megamenu-links:not(.megamenu-links--root) .megamenu-links__item {
  line-height: 16px;
}

.megamenu-links:not(.megamenu-links--root) .megamenu-links__item + .megamenu-links__item {
  margin-top: 9px;
}

.megamenu-links:not(.megamenu-links--root) .megamenu-links__item-link {
  font-weight: 400;
  font-size: 14px;
  color: #6c757d;
}

.megamenu-links:not(.megamenu-links--root) .megamenu-links__item-link:hover {
  color: #162d41;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .megamenu-links__item-link {
    font-size: 15px;
  }
}

/*
// .menu
*/
.menu {
  color: #262626;
  background-color: #fff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 8px 0;
  width: 200px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}

.menu__submenu {
  position: absolute;
  top: -8px;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s;
  right: 100%;
}

.menu__link {
  display: block;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 16px;
  padding-left: 28px;
}

.menu__link, .menu__link:hover {
  color: inherit;
}

.menu__arrow {
  fill: #bfbfbf;
  position: absolute;
  top: calc(50% - 5px);
  left: 14px;
}

.menu__arrow svg {
  display: block;
  transform: scaleX(-1);
}

.menu__item:hover > .menu__link {
  background: #f2f2f2;
}

.menu__item:hover > .menu__submenu {
  opacity: 1;
  visibility: visible;
}

.menu__item--has-submenu {
  position: relative;
}

/*
// .phone
*/
/*
// .search
*/
.search__dropdown {
  color: #262626;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 100%;
  border-radius: 1.5px;
  opacity: 0;
  transform: translateY(26px);
  visibility: hidden;
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0s 0.25s;
  right: 0;
  left: 0;
}

.search__dropdown--open {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition-delay: 0s;
}

.search__button-icon svg {
  display: block;
}

.search__body {
  position: relative;
  display: flex;
  z-index: 1;
  height: 44px;
}

.search__decor {
  position: absolute;
  display: flex;
  bottom: -10px;
  height: 34px;
  width: calc(100% + 20px);
  z-index: -1;
  right: -10px;
}

.search__decor-start,
.search__decor-end {
  overflow: hidden;
  height: 100%;
  flex-grow: 1;
  padding: 0 10px;
}

.search__decor-start:before {
  content: '';
  display: block;
  height: 24px;
  border-bottom-right-radius: 2px;
  margin-left: -20px;
  transform: skewX(-30deg);
  transform-origin: right top;
}

.search__decor-end:before {
  content: '';
  display: block;
  height: 24px;
  border-bottom-left-radius: 2px;
  margin-right: -20px;
  transform: skewX(30deg);
  transform-origin: left top;
}

.search__box {
  position: absolute;
  top: calc((100% - 34px) / 2);
  height: calc(34px);
  transition: color .2s, opacity .2s;
  opacity: 0;
  color: #f0f0f0;
}

.search__box:before, .search__box:after {
  position: absolute;
  content: '';
  display: block;
  background: currentColor;
  height: 100%;
  width: 80%;
  top: 0;
  border-radius: 3px;
}

.search__box:before {
  right: 0;
  transform: skewX(-30deg);
}

.search__box:after {
  left: 0;
  transform: skewX(30deg);
}

.search__input {
  width: 0;
  flex-shrink: 1;
  flex-grow: 1;
  background: transparent;
  border: none;
  padding: 0 12px;
  text-align: center;
  font-size: 15px;
  margin: 6px 0;
  z-index: 1;
  appearance: none;
  color: #262626;
}

.search__input::placeholder {
  color: #999;
}

.search__input::-ms-clear {
  display: none;
}

.search__input::placeholder {
  transition: opacity .15s, transform .15s, color .15s;
}

.search__input:focus {
  outline: none;
}

.search__input:focus::placeholder {
  opacity: 0;
  transform: translateY(-26px);
}

.search__input:hover ~ .search__box {
  opacity: 1;
  color: #f0f0f0;
}

.search__input:focus ~ .search__box {
  opacity: 1;
  color: #f0f0f0;
}

.search__button {
  margin: 5px 7px;
  width: 72px;
  padding: 0;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: currentColor;
  z-index: 1;
  pointer-events: none;
  position: relative;
  color: rgba(0, 0, 0, 0.3);
}

.search__button:after {
  background: transparent;
}

.search__button:focus {
  outline: none;
}

.search__button:before {
  position: absolute;
  display: block;
  content: '';
  width: 3px;
  top: 0;
  bottom: 0;
  background: #ebebeb;
  z-index: -1;
  border-radius: 1.5px;
  right: -8px;
  transform: skewX(30deg);
}

.search__button:after {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  z-index: -1;
  border-radius: 3px;
  transition: background .12s;
  right: 0;
  transform: skewX(30deg);
}

.search__button:hover:after,
.search__button--hover:after {
  background: #f0f0f0;
}

.search__button:active,
.search__button--hover:active {
  transition-duration: 0s;
}

.search__button:active:after,
.search__button--hover:active:after {
  background: #e5e5e5;
}

.search__button-title {
  display: none;
}

.search__button--start {
  order: -1;
}

.search__button--start:before {
  right: auto;
  left: -8px;
  transform: skewX(-30deg);
}

.search__button--start:after {
  transform: skewX(-30deg);
}

.search__shadow {
  position: absolute;
  bottom: 100%;
  height: 10px;
  width: calc(100% - 20px);
  overflow: hidden;
  right: 10px;
}

.search__shadow:before {
  display: block;
  content: '';
  width: 100%;
  height: 60px;
  position: relative;
  top: 100%;
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.12), 0 -1px rgba(0, 0, 0, 0.02);
  border-radius: 120px / 10px;
}

.search__dropdown {
  top: calc(100% + 5px);
  right: 14px;
  left: 14px;
}

.search__decor-start:before {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.search__decor-end:before {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}

.search__box {
  left: 92px;
  right: 92px;
}

/*
// .suggestions
*/
.suggestions {
  padding: 14px 0;
}

.suggestions__group + .suggestions__group {
  margin-top: 10px;
}

.suggestions__group-title {
  padding: 3px 20px 3px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #999;
}

.suggestions__category {
  display: block;
  padding: 6px 20px;
  color: inherit;
  font-size: 15px;
  line-height: 17px;
}

.suggestions__category:hover {
  color: inherit;
  background: #f2f2f2;
}

.suggestions__product {
  display: flex;
  padding: 6px 20px;
  color: inherit;
}

.suggestions__product:hover {
  color: inherit;
  background: #f2f2f2;
}

.suggestions__product-image {
  position: relative;
  border-radius: 1px;
  overflow: hidden;
  flex-shrink: 0;
  width: 40px;
  background: #fff;
  margin-left: 10px;
}

.suggestions__product-image:before {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: inherit;
  pointer-events: none;
  z-index: 1;
}

.suggestions__product-info {
  flex-grow: 1;
  flex-shrink: 1;
  padding-top: 4px;
  min-width: 0;
}

.suggestions__product-name {
  font-size: 15px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.suggestions__product-rating {
  display: flex;
  margin-top: 2px;
}

.suggestions__product-rating-stars {
  margin-left: 8px;
}

.suggestions__product-rating-label {
  font-size: 13px;
  line-height: 1;
  color: #6c757d;
}

.suggestions__product-price {
  white-space: nowrap;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 700;
  padding-top: 3px;
  width: 70px;
  margin-right: 14px;
  text-align: left;
}

/*
// .topbar
*/
.topbar {
  display: flex;
  height: 100%;
  font-size: 14px;
  line-height: 1;
}

.topbar__item-spring {
  flex-grow: 1;
}

.topbar__item-text {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.topbar__link {
  transition: color .12s;
}

.topbar__link-label {
  transition: inherit;
}

.topbar__button {
  display: flex;
  align-items: center;
  position: relative;
  background: transparent;
  font-family: inherit;
  border: none;
  padding: 0 10px;
  transition: background .12s;
  height: 100%;
}

.topbar__button:focus {
  outline: none;
}

.topbar__button-title,
.topbar__button-label {
  transition: color .12s;
}

.topbar__button-label {
  margin-left: 3px;
}

.topbar__button--has-arrow {
  padding-left: 18px;
  padding-left: 22px;
}

.topbar__button-arrow {
  position: absolute;
  fill: currentColor;
  top: calc(50% - 3px);
  left: 8px;
  left: 10px;
}

.topbar__button-arrow svg {
  display: block;
}

.topbar__menu {
  position: relative;
}

.topbar__menu-body {
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.topbar__menu-body {
  color: #262626;
  background-color: #fff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 150px;
  padding: .5rem 0;
  font-size: 14px;
  line-height: 26px;
  z-index: 1;
  font-weight: 500;
  left: 0;
}

.topbar__menu-item {
  display: block;
  padding: 3px 1rem;
  color: #262626;
}

.topbar__menu-item:hover {
  color: #262626;
  background: #f2f2f2;
}

.topbar__menu-item img {
  margin-left: 8px;
}

.topbar__menu-item span {
  position: relative;
  top: 1px;
}

.topbar__menu--open .topbar__menu-body {
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
  opacity: 1;
  transform: rotateX(0deg);
}

.topbar--spaceship-end {
  justify-content: flex-end;
}

.topbar--spaceship-start {
  background: #204260;
}

.topbar--spaceship-start .topbar__item-text {
  color: #fff;
  font-weight: 500;
}

@media (max-resolution: 1dppx) {
  .topbar--spaceship-start .topbar__item-text {
    font-weight: 400;
  }
}

.topbar--spaceship-start .topbar__link {
  color: #fdfdfd;
  font-weight: 400;
}

.topbar--spaceship-start .topbar__link:hover {
  color: #fff;
}

.topbar--spaceship-start .topbar__button-label {
  color: #fdfdfd;
  font-weight: 400;
}

.topbar--spaceship-start .topbar__button-title {
  color: #fff;
  font-weight: 500;
}

@media (max-resolution: 1dppx) {
  .topbar--spaceship-start .topbar__button-title {
    font-weight: 400;
  }
}

.topbar--spaceship-start .topbar__button-arrow {
  color: rgba(0, 0, 0, 0.25);
}

.topbar--spaceship-start .topbar__menu--open .topbar__menu-button,
.topbar--spaceship-start .topbar__button:hover {
  background: rgba(0, 0, 0, 0.15);
}

.topbar--spaceship-start .topbar__menu--open .topbar__menu-button .topbar__button-label,
.topbar--spaceship-start .topbar__button:hover .topbar__button-label {
  color: #fff;
}

.topbar--spaceship-end {
  background: #D62828;
}

.topbar--spaceship-end .topbar__item-text {
  color: #fff;
  font-weight: 500;
}

@media (max-resolution: 1dppx) {
  .topbar--spaceship-end .topbar__item-text {
    font-weight: 400;
  }
}

.topbar--spaceship-end .topbar__link {
  color: #fff;
  font-weight: 400;
}

.topbar--spaceship-end .topbar__link:hover {
  color: #fff;
}

.topbar--spaceship-end .topbar__button-label {
  color: #fff;
  font-weight: 400;
}

.topbar--spaceship-end .topbar__button-title {
  color: #fff;
  font-weight: 500;
}

@media (max-resolution: 1dppx) {
  .topbar--spaceship-end .topbar__button-title {
    font-weight: 400;
  }
}

.topbar--spaceship-end .topbar__button-arrow {
  color: #808080;
}

.topbar--spaceship-end .topbar__menu--open .topbar__menu-button,
.topbar--spaceship-end .topbar__button:hover {
  background: rgba(255, 255, 255, 0.08);
}

.topbar--spaceship-end .topbar__menu--open .topbar__menu-button .topbar__button-label,
.topbar--spaceship-end .topbar__button:hover .topbar__button-label {
  color: #fff;
}

/*
// .vehicle-picker
*/
.vehicle-picker__panel {
  display: none;
}

.vehicle-picker__panel--active {
  display: block;
}

.vehicle-picker__panel-body {
  padding: 20px 20px 28px;
}

.vehicle-picker__text {
  font-size: 15px;
  color: #6c757d;
  margin-bottom: 8px;
}

.vehicle-picker__text:first-child {
  margin-top: -5px;
}

.vehicle-picker__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
}

.vehicle-picker__actions > * + * {
  margin-right: 20px;
}

.search__car-selector-link a {
  font-size: 14px;
  color: #999;
}

.search__car-selector-link a:hover {
  text-decoration: underline;
}

/* ----------------------------------------
// Pages
// ---------------------------------------- */
/*
// .about
*/
@media (min-width: 1200px) {
  .about__body {
    grid-template-rows: 124px auto auto;
  }
}

/* ----------------------------------------
// Blocks
// ---------------------------------------- */
/*
// .block-header
*/
@media (min-width: 1200px) {
  .block-header:not(.block-header--has-breadcrumb) .block-header__title {
    padding-top: 60px;
  }
}

/*
// .block-space
*/
/* ----------------------------------------
// Blog
// ---------------------------------------- */
/*
// .post-header
*/
@media (min-width: 1200px) {
  .post-header {
    padding-top: 24px;
  }
}
