/*
// .breadcrumb
*/
@import '../variables';


@if($header-layout == classic) {
    .breadcrumb__spaceship-safe-area {
        display: none;
    }
}
